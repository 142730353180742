/*== Job board dropdown ==*/

.dropdown.job-board, .job-board .u-dropdown {
	background-color: #3b3094;
}

.dropdown.show .u-dropdown {
	max-height: 300px;
	opacity: 1;
	/* width: max-content; */
	min-width: 230px;
	
}
.status-change-btn{
	border-color: transparent !important;
}
.dropdown.show .u-dropdown .dropdown-divider {
	margin:0px;
	border-top: 1px solid #757575;
}

.u-dropdown a:hover {
	background: #5B5B5B !important;
	border-radius: 0px;
}

.job-board .u-dropdown a:hover {
    background: #4133af !important;
    border-radius: 0px;
}

.job-board .u-dropdown {
	width: 230px;
}

.job-board .dropdown-menu {
	box-shadow: 0 0 60px rgba(14,42,71,.25);
	border-radius: 0px !important;
}

.job-board .dropdown-menu a {
	font-size: 13px;
	padding: .25rem 1rem;
	word-break: break-word;
}

.job-board a::after {
	transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	transition: transform .2s cubic-bezier(.8,.2,.5,.1), background .2s ease-in-out;
}

.job-board.show a::after {
	transform: rotate(-180deg);
	-webkit-transform: rotate(-180deg);
	transition: transform .2s cubic-bezier(.8,.2,.5,.1), background .2s ease-in-out;
}
.job-board .dropdown-toggle img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.job-board a {
    background: transparent;
}
.job-board a:hover {
    background: transparent;
}

/* new design for dropdown header */


.lang-dropdown {
	display: inline-block;
}

#dLabel {
    width: 80px;
    height: 33px;
    border-radius: 6px;
    background-color: #ececec;
    border: solid 1px #ececec;
    text-align: left;
    padding: 5px 10px;
    color: #000;
    letter-spacing: 0.7px;
    /* margin-top: 25px; */
    /* display: inline-block; */
    /* float: left; */
    font-size: 14px;
    line-height: 20px;
	position: relative;
	top: -2px;
	font-weight: 600;
}
.v_zone #dLabel {
	width: 120px;
	top: 0;
}
.v_zone-dsk #dLabel {
	width: 80px;
}
#dLabel::after {
	content: '';
	position: absolute;
	top: 12px;
	right: 8px;
	width: 0; 
	height: 0; 
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #000;
}

.lang-dropdown.show #dLabel {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.header-social-icon.text-light:hover,
#dLabel:hover {
	color: var(--secondary) !important;
}
.lang-dropdown .dropdown-menu {
  min-width: 80px;
  border-radius: 6px;
  padding: 0;
  margin: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.v_zone.lang-dropdown .dropdown-menu, 
.v_zone-dsk.lang-dropdown .dropdown-menu {
	min-width: 120px;
}
.lang-dropdown.dropdown button:hover, .lang-dropdown.dropdown button:focus {
  border: none;
  outline: 0;
}

.lang-dropdown .dropdown.open button#dLabel {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
 
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  border: solid 1px #666;
   border-bottom: none;
}

.lang-dropdown .dropdown.open ul {
   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  border: solid 1px #666;
  border-top: none;
  height: 200px;
  overflow-y: scroll;
}

.lang-dropdown .dropdown-menu li {
 
     line-height: 1.5;
  letter-spacing: 0.7px;
  color: #666;
    font-size: 14px;
  cursor: pointer;
  padding: 7.5px 10px !important;
  border-top: solid 1px #f3f3f3;
}
.lang-dropdown .dropdown-menu li:hover {
  background-color: #ccc;
}

@media (max-width: 400px) {
	#dLabel {
		width: 70px;
		padding: 5px 7px;
		font-size: 12px;
	}
	
	.lang-dropdown .dropdown-menu {
		min-width: 70px;
	}
	
	.lang-dropdown .dropdown-menu li {
		padding: 5px 7px;
		font-size: 12px;
	}
}
@media (max-width: 992px) {
	.dsk-icon {
		display: none;
	}
}

.header-social-icon {
	background-color: #ececec;
	padding: 5px 7px !important;
	margin-right: 7px;
	margin-left: 3px;
	border-radius: 6px;
	position: relative;
	width: 33px;
	height: 33px;
	display: inline-block;
	text-align: center;
	color: #000 !important;
}
.header-social-icon.menu-btn {
    width: auto;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 600;
    line-height: 25px;
    cursor: pointer;
}

.header-social-icon i.fa-twitter {
	position:relative;
	left: 2px;
}


.mynav_toggler-b {
  width: auto;
  height: 30px;
  background: none;
  border-radius: 0px;
  text-align: left;
  border-right: 1px solid rgb(255 255 255 / 0.15) !important;
  color: var (--hover_color);
  position: relative;
  top:-2px

}

.mynav_toggler-b i {
  font-size: 25px;
}
/* JOB BOARD DROPDOWN END */
/*== NEW SIDE BAR FOR V ZONE 14 JUNE 2021 New Css==*/
.participants-header {
    background: transparent linear-gradient(180deg, #373D42 0%, #000000 100%) 0% 0%;
    background-repeat: no-repeat;
}
.v-zone-mob li {
	text-align: center;
}

.v-zone-mob li a {
	padding: 7px 10px;
}

.navbar-toggler-p.active {
	background: #373D42;
}

.vz-event-menu {
	background-color: #fff;
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 425px;
	z-index: 9999;
	right: -500px;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

.vz-event-menu.actives {
	right: 0;
}

.vz-menu-close {
	height: 100%;
    width: 100%;
    top: 0;
    z-index: 1031;
    background: rgba(0,0,0,.6);
	position: fixed;
	display:none;
	/*transition: all .3s ease;
	-webkit-transition: all .3s ease;
	animation: fadeIns .5s;
	-webkit-animation: fadeIns .5s;
	-moz-animation: fadeIns .5s;
	-o-animation: fadeIns .5s;
	-ms-animation: fadeIns .5s;*/
}

.fadeIns {
	animation: fadeIns .5s;
	-webkit-animation: fadeIns .5s;
	-moz-animation: fadeIns .5s;
	-o-animation: fadeIns .5s;
	-ms-animation: fadeIns .5s;
	display: block;
}

.fadeouts {
	animation: fadeouts .5s;
	-webkit-animation: fadeouts .5s;
	-moz-animation: fadeouts .5s;
	-o-animation: fadeouts .5s;
	-ms-animation: fadeouts .5s;
	display: block;
}

@keyframes fadeIns {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeouts {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		display: block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
}

.vz-event-menu .event-title {
	color: #fff;
	font-weight: 600;
	border-radius: 0px;
	height: auto;
}
.cross-btn {
	position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    background: rgb(248 249 250 / 12%);
    padding: 5px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.cross-btn i {
	font-size: 16px;
    color: #fff;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.cross-btn:hover {
	background: var(--secondary);
}
.vz-event-menu ul li a.active {
	/* color: var(--primary) !important; */
	border-radius: 0px !important;
}
.vz-event-menu ul li a {
	color: #000;
	padding: 15px 0px;
	font-weight: 600;
	font-size: 14px;
}
/* .vz-event-menu ul li a:hover {
	background-color:var(--primary) !important;
	color: white !important;
} */
.vz-menu-footer {
	position: relative !important;
    margin-top: 30px;
	/* bottom: 0;
	left: 0;
	width: 100%; */
}
.vz-event-menu ul.dropdown-ul li {
	list-style-type: none;
}

.visit-text{
	font-size: calc(15px + 0.2vw);
	color : #F56A57;
	margin-top: 15px;
}

.visit-text:hover{
	color : #F56A57 !important;
}

a {
  color: #FFFFFF !important;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #F56A57 !important;
  text-decoration: underline;
}

.btn{
	color : #F56A57 !important
}

.meni-iitem:hover{
	background-color: #2F2D32;
}

.vz-event-menu ul.dropdown-ul li a {
	font-weight: 400;
	padding: 5px 0px 5px 0px;
}
.vz-event-menu ul.dropdown-ul li a:hover {
	background-color: var(--primary);
	/* opacity: 0.5; */
	color: #fff !important;
}
.vz-event-menu ul.dropdown-ul {
	padding-bottom: 15px;
}
.vz-event-menu .menu-center {
	overflow: hidden;
	overflow-y: auto;
	height: 75vh;
}

.vz-event-menu .menu-center::-webkit-scrollbar {
    width: 5px;
}
 
.vz-event-menu .menu-center::-webkit-scrollbar-thumb {
    border-radius: 0px;
	background:#B7B6B6;
}
.vz-menu-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}


/* ==============   New Code    ============ */

form{
  border-radius: 48px;
  padding:4px;
  max-width: 400px;
  background-color: #2C2E33;
  max-height:58px
}

fieldset:not(:last-child){
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

legend{
  color: #FFF;
}

.radio-block {
  position: relative;
  display: flex;
  max-width: 400px;
  border:none;
  color: #FFF;
  background-color: transparent;
  border-radius: 48px;
}

.selected {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(50% - 1px);
  height: calc(100% - 2px);
  border-radius: 48px;
  transition: transform 0.5s;
  background-color: #F56A57 !important;
}

label {
  position: relative;
  z-index: 100;
  width: 50%;
  /* padding: 10px;
  height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s;
  text-align: center;
  cursor: pointer;
  margin-bottom:5px
}

input[type=radio]:checked + label {
  color: #FFF;
}

input[type=radio]:checked + .off-label + .selected {
  transform: translateX(100%);
}

/* ------ general style ------ */
:root {
  --blue: #104fa8;
}

.sr-only, input[type=radio]{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
}



/* ==============   New Code End   ============ */






@media (max-width : 1024px){
	.visit-text{
		margin-top: 30px;
	}
}


@media (max-width: 992px) {
	.dropdown .u-dropdown{
		width:230px;
		border:0;
	}

	.visit-text{
		font-size: 20px;
		text-decoration: none !important;
	}

	
	.v-zone-nc {
		position: absolute;
		left: 0px;
		width: 100%;
		background: rgb(42, 46, 50);
		z-index: 99999;
		top: 50px;
		right: 0px;
	}
	
	#OpenEvent-menu.menu-btn {
		background: var(--secondary) !important;
		color: #fff !important;
		font-size: 22px;
		line-height: 30px;
		width: 40px;
		height: 40px;
		border-radius: 100%;
		position: relative;
		top: -5px;
	}
	
	.vz-event-menu ul li a {
		padding: 15px 15px;
		font-size: 13px;
	}
	
	.vz-event-menu ul li a.header-social-icon {
		line-height: 22px;
		font-weight: 600;
	}
	
	.vz-event-menu ul li a.header-social-icon .fa-phone {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
	} 
	
	.v_zone #dLabel {
		font-size: 12px;
	}
	
	.participants-header .participant-navbar ul li a {
		text-align: left !important;
		border-bottom: 1px solid #FFFFFF0F;
		min-height: auto;
		line-height: 37px;
		font-size: 14px;
	}
	
	.participants-header .participant-navbar ul.v-zone-mob li a {
		padding: 5px 10px !important;
		text-align: center !important;
		min-height: 50px;
		border-bottom: 0px;
	}
	
	.participant-navbar ul.v-zone-mob li .dropdown .u-dropdown a {
		text-align: left !important;
	}
	.vz-event-menu .menu-center {
		overflow: hidden;
		height: 80vh;
		overflow-y: scroll;
	}
	.cross-btn {
		top: 20px;
		left: -35px;
		background: #fff;
		z-index: 999;
		border-radius: 0;
	}
	
	.cross-btn i {
		color: #000;
	}
}

@media (max-width: 768px) {
	
	.vz-event-menu {
		width: 317px;
	}
	.participants-chat .chat-list{
		height:calc(100% - 100px) !important;
		max-height: 414px !important;
	  }

	  .visit-text{
		font-size: 18px;
		margin-top: 30px
	}
}

@media (max-width : 540px){
	.visit-text{
		font-size: 13px;
		margin-bottom: 20px;
	}
}

@media (max-width : 360px){
	input[type=radio]:checked + label{
		font-size: 13px;
	}

	label{
		font-size: 13px;
	}
}



.notification-placeholder {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.status-loader{
	display:flex;
	justify-content: center;
}
.participants-header .participant-navbar a.p_name span {
	display: none; 
}

/* .participants-header .participant-navbar a.p_name img {
	width: 30px;
	height: 30px;
} */
.participants-header .participant-navbar a.p_name .img-div {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-left: 10px;
	display: inline-table;
	text-align: center;	
	position: relative;
}

.participants-header .participant-navbar a.p_name .img-div img {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-left: 0 !important;
}

.participants-header .participant-navbar a.p_name .img-div::after {
	content: '';
	position: absolute;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	right: 0;
	top: 4px;
	background-color:#C2C2C2 ;
	border: 2px solid #fff;
}

.participants-header .participant-navbar a.p_name .img-div.invisible-status::after {
	background-color: #2C3135;
	border: 2px solid #C2C2C2;
	left: 18px;
}

.participants-header .participant-navbar a.p_name .img-div.online::after {
	background-color: #36d108;
	border: 2px solid #fff;
	left: 18px;
}
.participants-header .participant-navbar a.p_name .img-div.offline::after {
	background-color: gray;
	border: 2px solid #fff;
	left: 18px;
}
.disable-btn{
	pointer-events: none
}
.status-loader1{
	display:flex;
	flex-direction: row-reverse;
	column-gap: 5px;
	align-items: center;
}

.header a.bg_secondary2 {
	background: transparent linear-gradient(180deg, #FBCA2F 0%, #E3723C 100%) !important;
	color: #fff;
	border-radius: 24px;
	border: none !important;
}

.header a.bg_secondary2:hover {
	box-shadow: 0px 3px 6px #00000029 !important;
}

/* .header .navbar .navbar-nav li a.text-light {
	color: #000 !important;
} */

.header .navbar .navbar-nav li.active2 a {
	background: transparent linear-gradient(180deg, #FBCA2F 0%, #E3723C 100%);
	color: #fff !important;
	border-radius: 24px;
}

/* .header .navbar .navbar-nav li:hover a {
	background: transparent  !important;
	color: #009AC8 !important;
} */

/* .affix  {
	background-color: #fff !important;
} */
.telehealthHeadrBack{
	background-color:#fff
}
.active2{
	background: transparent linear-gradient(180deg, #FBCA2F 0%, #E3723C 100%);
	color: #fff !important;
	border-radius: 24px;
}
.dropdown-item{
	word-break: break-word;
}