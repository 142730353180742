.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.custom-youtube-player {
  position: fixed;
  width: 450px;
  height: 300px;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
}
.custom-youtube-player.navigate{
	position: absolute;
    right: 65px;
    top: -30px;
    width: 32px;
    height: 32px;
    background: #000;
    color: #fff;
    opacity: 1;
    padding: 3px;
    cursor: pointer;
    align-items: center;
}
.custom-youtube-player .close,
.custom-youtube-player .handle {
  position: absolute;
  right: 0px;
  top: -30px;
  width: 32px;
  height: 32px;
  background: #000;
  color: #fff;
  opacity: 1;
  padding: 3px;
  cursor: pointer;
}
.custom-youtube-player .handle {
  right: 32px;
  padding-top: 8px;
  cursor: move;
}
.react-resizable{
  background-color: #000 !important;
}


body{
  background-color: #1B191E;
}

.centered-div {
    position: absolute; /* Set child position to absolute */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}